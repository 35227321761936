import { useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { useTheme } from '@/theme';
import { exitModalText, isHomePath } from '@/theme/vars';
import { useAppDispatch, useAppSelector } from '@/state/store';
import {
    setIsExitLocationModalOpen,
    openProfile,
    closeProfile,
    toggleInventory,
    toggleShowingSettingsMenu,
    toggleShopOpen,
    setIsSupplyRunLeaderboardOpen,
} from '@/state/features/app';
import { sendEvent } from '@/utils/analytics';
import { isMobileWidth } from '@/utils/mobile';
import IconButton from '@/components/IconButton';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';

import * as styles from './styles.module.scss';

export default function FloatingHeaderBar({ classname }: { classname?: string }) {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const { isProfileOpen, isInventoryOpen, isShopOpen, isSettingsMenuShowing, isExitLocationModalOpen, hasSeenShop } =
        useAppSelector((state) => state.app);
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);

    const [isExpanded, setIsExpanded] = useState(false);

    const { locationId } = useParams();

    // Some buttons are only available inside locations
    const isInLocation = locationId;
    const canShowExitModal = !isHomePath();
    const hasShopButton = isInLocation && (theme.metadata?.hasProfileShop || theme.metadata?.hasExternalShop);

    return (
        <div className={classNames(styles.FloatingHeaderBar, classname, { [styles.isExpanded]: isExpanded })}>
            <button
                className={styles.expandButton}
                onClick={() => setIsExpanded(!isExpanded)}
                aria-label={isExpanded ? 'Collapse menu' : 'Expand menu'}
            >
                <img className={styles.logoIcon} src={theme.files.logo} alt="Logo Icon" />
                <div className={styles.buttonStatus}>
                    <Icon type={isExpanded ? 'close' : 'menu'} size={12} />
                </div>
                <Tooltip isVisible={hasShopButton && !hasSeenShop && isMobileWidth()}>New Shop</Tooltip>
            </button>
            <div className={styles.buttonsContainer} inert={!isExpanded ? '' : undefined}>
                {isInLocation && (
                    <IconButton
                        buttonStyle="round"
                        iconType="inventory"
                        title={isInventoryOpen ? 'Close Inventory' : 'Open Inventory'}
                        ariaLabel={isInventoryOpen ? 'Close Inventory' : 'Open Inventory'}
                        className={styles.button}
                        onClick={() => {
                            dispatch(toggleInventory());
                            dispatch(setIsExitLocationModalOpen(false));
                            dispatch(setIsSupplyRunLeaderboardOpen(false));
                            sendEvent(isInventoryOpen ? 'close_inventory' : 'open_inventory');
                        }}
                    />
                )}
                {isInLocation && (
                    <IconButton
                        buttonStyle="round"
                        iconType="profile"
                        title={isProfileOpen ? 'Close Profile' : 'Open Profile'}
                        ariaLabel={isProfileOpen ? 'Close Profile' : 'Open Profile'}
                        onClick={() => {
                            isProfileOpen ? dispatch(closeProfile()) : dispatch(openProfile(currentUserProfile));
                            isProfileOpen
                                ? sendEvent('close_profile_from_main_menu')
                                : sendEvent('open_profile_from_main_menu');
                        }}
                        className={styles.button}
                    />
                )}
                {hasShopButton && (
                    <IconButton
                        buttonStyle="round"
                        iconType="shop"
                        title={isShopOpen ? 'Close Shop' : 'Open Shop'}
                        ariaLabel={isShopOpen ? 'Close Shop' : 'Open Shop'}
                        onClick={() => {
                            dispatch(toggleShopOpen());
                            isShopOpen ? sendEvent('close_shop_from_main_menu') : sendEvent('open_shop_from_main_menu');
                        }}
                        className={styles.button}
                        hasDot={!hasSeenShop && isExpanded}
                    />
                )}
                {canShowExitModal && (
                    <IconButton
                        buttonStyle="round"
                        iconType="world"
                        title={exitModalText}
                        ariaLabel={exitModalText}
                        className={styles.button}
                        onClick={() => {
                            if (!isExitLocationModalOpen) {
                                dispatch(setIsExitLocationModalOpen(true));
                                dispatch(setIsSupplyRunLeaderboardOpen(false));
                                sendEvent('open_exit_modal');
                            } else {
                                dispatch(setIsExitLocationModalOpen(false));
                            }
                        }}
                    />
                )}
                <IconButton
                    buttonStyle="round"
                    iconType="settings"
                    title={isSettingsMenuShowing ? 'Close Settings' : 'Open Settings'}
                    ariaLabel={isSettingsMenuShowing ? 'Close Settings' : 'Open Settings'}
                    onClick={() => {
                        dispatch(toggleShowingSettingsMenu());
                        isSettingsMenuShowing ? '' : sendEvent('open_settings');
                    }}
                    className={styles.button}
                />
            </div>
        </div>
    );
}
