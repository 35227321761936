import * as Sentry from '@sentry/react';

export function initSentry() {
    const release = process.env.PUBLIC_ENV !== 'dev' ? `arise-frontend@${process.env.GIT_TAG}` : undefined;
    if (release) console.info(`Version ${process.env.GIT_TAG}`);
    Sentry.init({
        dsn: process.env.PUBLIC_SENTRY_DSN,
        integrations: [],
        environment: process.env.PUBLIC_ENV,
        release: release,
    });
    Sentry.setTag('instance', process.env.PUBLIC_INSTANCE);
}
