import React, { useEffect, useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { sendEvent } from '@/utils/analytics';
import { useAppDispatch, useAppSelector } from '@/state/store';
import { setCurrentlyViewingMedia } from '@/state/features/app';
import IconButton from '@/components/IconButton';

import * as styles from './styles.module.scss';

export default function MediaViewer() {
    const dispatch = useAppDispatch();
    const currentlyViewingMedia = useAppSelector((state) => state.app.currentlyViewingMedia);
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (currentlyViewingMedia?.resourceUrl) {
            sendEvent('view_media', currentlyViewingMedia);

            setTimeout(() => {
                closeButtonRef.current?.focus();
            }, 200);
        }
    }, [currentlyViewingMedia?.resourceUrl]);

    if (!currentlyViewingMedia?.resourceUrl || !currentlyViewingMedia?.resourceType) return null;

    const ImageContainer = () => (
        <TransformWrapper
            limitToBounds={true}
            centerOnInit={true}
            doubleClick={{ mode: 'toggle' }}
            centerZoomedOut={true}
            pinch={{ step: 10 }}
            panning={{ velocityDisabled: true }}
        >
            <TransformComponent wrapperClass={styles.transformWrapper} contentClass={styles.transformContent}>
                <img src={currentlyViewingMedia.resourceUrl} alt={currentlyViewingMedia.description ?? ' '} />
            </TransformComponent>
        </TransformWrapper>
    );

    const VideoContainer = () => {
        if (currentlyViewingMedia.resourceUrl.includes('youtube.com/watch')) {
            const videoId = new URL(currentlyViewingMedia.resourceUrl).searchParams.get('v');
            if (videoId) {
                const embedUrl = `https://www.youtube.com/embed/${videoId}`;
                return (
                    <iframe
                        src={embedUrl}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                );
            }
            console.warn('Invalid YouTube video URL');
        } else {
            console.warn('Unrecognised video type');
        }
        console.warn('Cannot display content');
        return 'Cannot display content';
    };

    const getMedia = () => {
        switch (currentlyViewingMedia.resourceType) {
            case 'image':
                return ImageContainer();
            case 'video':
                return VideoContainer();
            default:
                console.warn('Cannot display content, unrecognised type: ' + currentlyViewingMedia.resourceType);
                return 'Cannot display content';
        }
    };

    return (
        <div className={styles.MediaViewer}>
            <div className={styles.overlay}></div>
            <div className={styles.modalContent} aria-modal>
                <IconButton
                    iconType="close"
                    onClick={() => dispatch(setCurrentlyViewingMedia(null))}
                    className={styles.closeButton}
                    buttonStyle="round"
                    ariaLabel="Close Image"
                    buttonRef={closeButtonRef}
                />
                <div className={styles.mediaContainer}>{getMedia()}</div>
            </div>
        </div>
    );
}
