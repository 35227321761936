import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import ChatBar from '@/components/Forms/ChatBar';
import { useAppSelector } from '@/state/store';
import { useTheme } from '@/theme';
import { useCurrentUserIsGuest } from '@/hooks/users';
import FeedInputOverlay from '@/components/Feed/FeedInputOverlay';
import FrozenFeedInputOverlay from './FrozenFeedInputOverlay';
import FailedInputOverlay from '@/components/Feed/FailedInputOverlay';
import { BAN_MESSAGES_UNTHEMED, BAN_MESSAGE_FALLBACK, BanMessages } from './content';
import FeedScroller from '@/components/Feed/FeedScroller';
import IconButton from '@/components/IconButton';

import * as styles from './styles.module.scss';

export default function Feed() {
    const theme = useTheme();
    const isGuest = useCurrentUserIsGuest();
    const { roomId, messages: msgs, feedUpdateTicker } = useAppSelector((state) => state.feed);
    const isFeedOpen = useAppSelector((state) => state.app.location.isFeedOpen);
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);

    const { locationId: id } = useParams<{ locationId: string }>();
    const scrollToBottomRef = useRef<() => void>(() => {});
    const shouldAutoScrollRef = useRef(true);

    const chatbarRecentlyResizedRef = useRef(false);
    const chatbarResizeTimeoutRef = useRef<any>(null);

    const [showScrollToBottom, setShowScrollToBottom] = useState(false);

    const enableScrollListenRef = useRef(false);

    useEffect(() => {
        setTimeout(() => {
            enableScrollListenRef.current = true;
        }, 1000);
    }, []);

    function handleScrollToBottomClick() {
        shouldAutoScrollRef.current = true;
        scrollToBottomRef.current();
        setShowScrollToBottom(false);
    }

    function handleScrollBack() {
        if (!enableScrollListenRef.current || chatbarRecentlyResizedRef.current) return;
        setShowScrollToBottom(true);
        shouldAutoScrollRef.current = false;
    }

    function handleScrollToBottom() {
        handleScrollToBottomClick();
    }

    function handleChatbarResize() {
        if (shouldAutoScrollRef.current) {
            setTimeout(() => {
                scrollToBottomRef.current();
            }, 100);
        }

        chatbarRecentlyResizedRef.current = true;
        if (chatbarResizeTimeoutRef.current) {
            clearTimeout(chatbarResizeTimeoutRef.current);
        }
        chatbarResizeTimeoutRef.current = setTimeout(() => {
            chatbarRecentlyResizedRef.current = false;
        }, 250);
    }

    const getBannedMessage = () => {
        const banMessages: BanMessages = {
            short_ban: theme.metadata?.shortBanMessage,
            permanent_ban: theme.metadata?.permanentBanMessage,
            rate_limit_exceeded: theme.metadata?.rateLimitExceededMessage,
        };

        const reasonId = currentUserProfile?.bannedReason;
        const themedMessage = banMessages[reasonId];
        const unthemedMessage = BAN_MESSAGES_UNTHEMED[reasonId];

        return themedMessage ?? unthemedMessage ?? BAN_MESSAGE_FALLBACK;
    };

    const renderInputContent = () => {
        if (!isFeedOpen) {
            return <FeedInputOverlay message="This chat is closed" />;
        }
        if (currentUserProfile?.isBanned) {
            return (
                <FrozenFeedInputOverlay message={getBannedMessage()} bannedUntil={currentUserProfile?.bannedUntil} />
            );
        }
        if (isGuest) {
            return null;
        }
        return (
            <ChatBar
                placeholder="Write a message..."
                onResize={handleChatbarResize}
                locationId={id}
                isAdmin={currentUserProfile?.role === 'superadmin'}
            />
        );
    };

    return (
        <div className={styles.Feed}>
            <div className={styles.messages}>
                <FeedScroller
                    locationId={id}
                    items={roomId === id ? msgs : []}
                    feedUpdateTicker={feedUpdateTicker}
                    shouldAutoScrollRef={shouldAutoScrollRef}
                    scrollToBottomRef={scrollToBottomRef}
                    onScrollBack={handleScrollBack}
                    onScrollToBottom={handleScrollToBottom}
                />

                <IconButton
                    className={classnames(styles.scrollToBottom, {
                        [styles.show]: showScrollToBottom,
                    })}
                    onClick={handleScrollToBottomClick}
                    iconType="arrow-downward"
                    ariaLabel="Resume auto scroll"
                    buttonStyle="round"
                />
            </div>
            <div className={styles.input}>
                {renderInputContent()}
                <FailedInputOverlay />
            </div>
        </div>
    );
}
