import { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import FullSizeBackground from '@/components/FullSizeBackground';
import FullpageWrapper from '@/components/FullpageWrapper';
import Sidebar from '@/components/Sidebar';
import IframeContainer from '@/components/IframeContainer';
import {
    loadLocation,
    setIsExitLocationModalOpen,
    setIsSnackbarVisible,
    setIsInventoryOpen,
    setArisewareIsSnackbarVisible,
    closeProfile,
    setIsSupplyRunLeaderboardOpen,
} from '@/state/features/app';
import { useAppDispatch, useAppSelector } from '@/state/store';
import InventoryDrawer from '@/components/InventoryDrawer';
import { usePusherSubscribe } from '@/arise/chat/pusher';
import { getPreviousMessages, clearCurrentEvent, loadAvailableReactions } from '@/state/features/feed';
import { pathToURL } from '@/utils/urls';
import FailOverlay from '@/components/FailOverlay';
import useFailed from '@/hooks/failed';
import AchievementFeed from '@/components/AchievementFeed';
import LocationIntroduction from '@/components/LocationIntroduction';
import QuickChatDrawer from '@/components/QuickChatDrawer';
import FloatingHeaderBar from '@/components/FloatingHeaderBar';
import ShareModal from '@/components/ShareModal';
import ExitModal from '@/components/ExitModal';
import SupplyRunLeaderboard from '@/components/SupplyRunLeaderboard';
import NewsModal from '@/components/NewsModal';
import GuestSignUpModal from '@/components/GuestSignUpModal';
import MediaViewer from '@/components/MediaViewer';
import { useAudio } from '@/hooks/audio';
import { sendEvent } from '@/utils/analytics';
import * as api from '@/arise/api';
import { homePath } from '@/theme/vars';

import * as styles from './styles.module.scss';

export default function LocationPage() {
    const navigate = useNavigate();
    const [isBackgroundReady, setIsBackgroundReady] = useState(false);
    const [isIFrameReady, setIsIFrameReady] = useState(false);
    const [locationIntroductionHasCompleted, setLocationIntroductionHasCompleted] = useState(false);
    const dispatch = useAppDispatch();
    const { audio } = useAudio();
    const {
        universeId,
        location,
        isLocationLoading,
        isSidebarOpen,
        isExitLocationModalOpen,
        isSupplyRunLeaderboardOpen,
        locationLoadErrorId,
        isInventoryOpen,
        isPurchaseInProgress,
    } = useAppSelector((state) => state.app);
    const currentUserProfile = useAppSelector((state) => state.profile.currentUserProfile);
    const { hasFailed } = useFailed();

    const { locationId: id } = useParams();

    const pusherChannel = useMemo(() => {
        if (location) {
            if (location.customJSON && location.customJSON.useGlobalChat) {
                return 'private-arise-global-chat-' + universeId;
            } else {
                return 'private-' + id;
            }
        }
        return '';
    }, [location]);

    usePusherSubscribe(pusherChannel, id);

    const locationIsClosed = !isLocationLoading && location?.id === id && !location.isOpen;

    useEffect(() => {
        // Redirect if closed
        // Allow superadmins to access location when closed
        if (locationIsClosed && currentUserProfile && currentUserProfile.role !== 'superadmin') {
            navigate(homePath);
        }
    }, [isLocationLoading, location, currentUserProfile?.role]);

    useEffect(() => {
        // Redirect if not found
        if (locationLoadErrorId === id) {
            navigate(homePath);
        }
    }, [locationLoadErrorId]);

    useEffect(() => {
        // Reset UI on load
        dispatch(setIsSnackbarVisible(true));
        dispatch(setArisewareIsSnackbarVisible(null));
        dispatch(setIsInventoryOpen(false));
        dispatch(setIsExitLocationModalOpen(false));
        dispatch(setIsSupplyRunLeaderboardOpen(false));
        dispatch(closeProfile());

        // Clear event on load
        // TODO: In future make call to check for events
        dispatch(clearCurrentEvent());

        if ((!location || location.id !== id) && !isLocationLoading) dispatch(loadLocation(id));
        dispatch(loadAvailableReactions());
        sendEvent('open_location', { locationId: id });

        api.heartbeat(id, true);
        const interval = setInterval(() => {
            api.heartbeat(id);
        }, 30 * 1000); // 30s

        return () => {
            clearInterval(interval);
        };
    }, [id]);

    useEffect(() => {
        if (!location) return;

        const globalChat = location.customJSON && location.customJSON.useGlobalChat;
        dispatch(getPreviousMessages({ id, globalChat }));
    }, [location]);

    useEffect(() => {
        // Play location background music from CMS
        audio.stopAll();
        if (location?.backgroundMusicURL) {
            const backgroundMusicId = `location-bg-music-${location.id}`;
            if (!audio.hasTrack(backgroundMusicId)) {
                audio.addTrack({
                    id: backgroundMusicId,
                    src: location.backgroundMusicURL,
                    ambient: true,
                    volume: 0.5,
                });
            }
            audio.playOnly(backgroundMusicId);
        }
    }, [location?.backgroundMusicURL]);

    useEffect(() => {
        if (isPurchaseInProgress) {
            // If doing a purchase, don't show location intro
            setLocationIntroductionHasCompleted(true);
        }
    }, [isPurchaseInProgress]);

    // TODO: loading
    if (!location || location.id !== id || (locationIsClosed && currentUserProfile?.role !== 'superadmin'))
        return <></>;

    const iframeURL = location.iframeURL || location.latestArisewareBuildURL;

    const isAModalOpen = isInventoryOpen || isExitLocationModalOpen || isSupplyRunLeaderboardOpen;
    const getBackgroundTransformOrigin = () => {
        const yValue = isSidebarOpen ? '70%' : 'center';
        const xValue = isAModalOpen || !isIFrameReady ? '60%' : 'center';
        return yValue + ' ' + xValue;
    };

    const renderContent = () => {
        return (
            <>
                <FullSizeBackground
                    imagePath={pathToURL(location.backgroundImagePath)}
                    blur={isBackgroundReady ? 0 : 10}
                    zoom={1.5}
                    transformOrigin={getBackgroundTransformOrigin()}
                    onImageLoaded={() => setIsBackgroundReady(true)}
                />
                <div
                    className={classNames(styles.contentWrapper, {
                        [styles['contentWrapper--sidebarOpen']]: isSidebarOpen,
                    })}
                >
                    <IframeContainer
                        src={iframeURL}
                        arisewareProjectID={location.arisewareProjectID}
                        style={{
                            transition: 'transform 310ms linear',
                            // transform: isAModalOpen || !isIFrameReady ? 'scale(0.85)' : 'scale(1)',
                        }}
                        onReady={() => setIsIFrameReady(true)}
                    />
                    <InventoryDrawer />
                    <ExitModal />
                    <SupplyRunLeaderboard />
                    <ShareModal />
                    <FailOverlay failed={hasFailed} />
                    <FloatingHeaderBar />
                </div>
                <QuickChatDrawer />
            </>
        );
    };

    if (location.hasRoomIntroduction && !locationIntroductionHasCompleted) {
        return (
            <LocationIntroduction
                titlePrefix={location.locationNamePrefix}
                titleText={location.name}
                text={location.roomIntroductionText}
                imageUrl={location.customJSON.roomIntroductionImage}
                hasSoundWarning={location.roomIntroductionHasSoundWarning}
                onComplete={() => setLocationIntroductionHasCompleted(true)}
            />
        );
    } else {
        return (
            <FullpageWrapper pageHasProfileDrawer>
                {renderContent()}
                <div
                    className={classNames(styles.sidebarWrapper, {
                        [styles['sidebarWrapper--sidebarOpen']]: isSidebarOpen,
                    })}
                >
                    {location && <Sidebar locationTitle={location.locationNamePrefix} locationName={location.name} />}
                </div>

                <MediaViewer />
                <AchievementFeed />
                <GuestSignUpModal />
                <NewsModal />
            </FullpageWrapper>
        );
    }
}
