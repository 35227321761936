import classNames from 'classnames';

import Button from '@/components/Forms/Button';
import { useNavigateToLogin, useNavigateToSignup } from '@/hooks/auth';
import { isInsidersInstance, isThoseBeyondInstance } from '@/theme/vars';
import { sendEvent } from '@/utils/analytics';
import { isMobileWidth } from '@/utils/mobile';

import * as styles from './styles.module.scss';

export default function GuestBanner() {
    const navigateToSignup = useNavigateToSignup();
    const navigateToLogin = useNavigateToLogin();

    const getLongText = () => {
        if (isInsidersInstance) return 'Join to chat, earn Skybound Insiders Points and experience more.';
        if (isThoseBeyondInstance) return 'Join the Those Beyond community to chat and experience more.';
        return 'Join the community to chat and experience more.';
    };
    const shortText = 'Join the community.';
    const text = isMobileWidth() ? shortText : getLongText();

    return (
        <div className={classNames(styles.GuestBanner, { [styles.thoseBeyondThemed]: isThoseBeyondInstance })}>
            {isThoseBeyondInstance && (
                <img className={styles.logo} alt="Those Beyond Logo" src="/assets/global/those-beyond-icon.svg"></img>
            )}
            <span>{text}</span>
            <div className={styles.buttons}>
                <Button
                    className={styles.loginButton}
                    onClick={() => {
                        sendEvent('guest_banner_log_in_click', {
                            message: text,
                        });
                        navigateToLogin();
                    }}
                >
                    Log In
                </Button>
                <Button
                    className={styles.loginButton}
                    onClick={() => {
                        sendEvent('guest_banner_sign_up_click', {
                            message: text,
                        });
                        navigateToSignup();
                    }}
                >
                    Sign Up
                </Button>
            </div>
        </div>
    );
}
